import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

// Connects to data-controller="slimselect"
export default class extends Controller {
  static values = {
    options: Object,
  };

  connect() {
    this.slimselect = new SlimSelect({
      select: this.element,
      settings: this.optionsValue,
    });
    this.slimselect.render.content.main.setAttribute("data-turbo-permanent", true);
  }

  disconnect() {
    this.slimselect.destroy();
  }
}
