// Inspired by: https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/popover.js

import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";
import { useClickOutside, useTransition } from "stimulus-use";

export default class UIPopover extends Controller {
  static values = {
    dismissAfter: Number,
  };
  static targets = ["content", "wrapper", "trigger"];

  connect() {
    useClickOutside(this);
    useTransition(this, {
      element: this.contentTarget,
      enterActive: "transition ease-out duration-300",
      enterFrom: "transform opacity-0 scale-95",
      enterTo: "transform opacity-100 scale-100",
      leaveActive: "transition ease-in duration-75",
      leaveFrom: "transform opacity-100 scale-100",
      leaveTo: "transform opacity-0 scale-95",
    });
    this.popperInstance = createPopper(this.triggerTarget, this.contentTarget, {
      placement: this.contentTarget.dataset.side || "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [8, 8],
          },
        },
      ],
    });
  }

  // Show the popover
  show() {
    this.enter();
    this.contentTarget.dataset.state = "open";
    this.triggerTarget.classList.remove(this.triggerTarget.dataset.activeClasses);
  }

  // Hide the popover
  hide() {
    this.leave();
    this.triggerTarget.classList.add(this.triggerTarget.dataset.activeClasses);
    this.contentTarget.dataset.state = "closed";
  }

  clickOutside(event) {
    this.triggerTarget.classList.remove(this.triggerTarget.dataset.activeClasses);
    this.leave();
  }

  // Toggle the popover on demand
  toggle(event) {
    this.popperInstance.update();
    if (this.contentTarget.classList.contains("hidden")) {
      this.show();

      if (this.hasDismissAfterValue) {
        setTimeout(() => {
          this.hide();
        }, this.dismissAfterValue);
      }
    } else {
      this.hide();
    }
  }
}
