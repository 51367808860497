import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="submit-on-change"
export default class extends Controller {
  connect() {
    if (!this.element.tagName === "FORM") console.warn("You sure you want an autosubmit that's not a form?");

    this.element.addEventListener("change", (e) => {
      this.element.requestSubmit();
    });
  }
}
