import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="swappable"
export default class extends Controller {
  static targets = ["a", "b"];

  connect() {
    const initialState = this.element.dataset.initialSwappable;
    if (initialState !== "a") {
      this.swap();
    }
  }

  swap() {
    this.aTarget.hidden = !this.aTarget.hidden;
    this.bTarget.hidden = !this.bTarget.hidden;

    const field = this.element.querySelector("input[type=text]");
    if (field) {
      field.focus();
      field.selectionStart = field.selectionEnd = field.value.length;
    }
  }
}
