import Sortable from "@stimulus-components/sortable";

export default class extends Sortable {
  connect() {
    super.connect();
  }

  get defaultOptions() {
    const urlParams = new URLSearchParams(window.location.search);

    return {
      forceFallback: urlParams.has("use_sortable_fallback"),
    };
  }

  onUpdate({ item, newIndex }) {
    // can't use request.js here - XHR requests handle redirects by keeping the method, so that means
    // a PATCH from request.js will remain that!

    const form = item.querySelector("form.edit-sortable");
    const positionField = form.querySelector("input[data-sortable-position-field]");
    positionField.value = newIndex;
    form.requestSubmit();

    return;
  }
}
