import { Controller } from "@hotwired/stimulus";

import { easepick } from "@easepick/core";
import { RangePlugin } from "@easepick/range-plugin";
import { PresetPlugin } from "@easepick/preset-plugin";
import { LockPlugin } from "@easepick/lock-plugin";
import { DateTime } from "@easepick/datetime";

const PERIOD_NAMES = {
  today: "Today",
  yesterday: "Yesterday",
  last_7_days: "Last 7 Days",
  last_30_days: "Last 30 Days",
  this_month: "This Month",
  last_month: "Last Month",
  all_time: "All Time",
  custom: "Custom Range",
};

// Connects to data-controller="date-picker"
export default class extends Controller {
  static targets = ["input", "name", "startDate", "endDate"];

  connect() {
    const cssUrl = this.element.dataset.cssUrl;
    const input = this.inputTarget;

    const initialRange = PERIOD_NAMES[this.inputTarget.value];
    const presets = this._presets();

    let startDate, endDate;

    if (presets[initialRange]) {
      [startDate, endDate] = presets[initialRange];
    } else {
      const [start, end] = initialRange.split(" - ");
      startDate = new Date(this.startDateTarget.value);
      endDate = new Date(this.endDateTarget.value);
    }

    this.picker = new easepick.create({
      element: input,
      css: [cssUrl],
      zIndex: 50,
      grid: 2,
      calendars: 2,
      mode: "range",
      plugins: [RangePlugin, PresetPlugin, LockPlugin],
      PresetPlugin: {
        customPreset: this._presets(),
      },
      RangePlugin: {
        startDate,
        endDate,
        strict: true,
      },
      LockPlugin: {
        maxDate: new Date(),
        presets: false,
        inseparable: true,
      },
      locale: {
        previousMonth: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-left"><path d="m15 18-6-6 6-6"/></svg>`,
        nextMonth: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6"/></svg>`,
      },
    });

    this.picker.on("select", (event) => {
      const { start, end } = event.detail;
      this.updateName(start, end);
      const changeEvent = new Event("change", { bubbles: true });
      this.inputTarget.dispatchEvent(changeEvent);
    });

    this.updateName(startDate, endDate);

    this.inputTarget.classList.add("invisible");
  }

  show() {
    this.picker.show();
  }

  updateName(start, end) {
    const presets = this._presets();
    let presetName = null;

    const preset = Object.entries(presets).find(([name, range]) => {
      return range[0].isSame(start, "day") && range[1].isSame(end, "day");
    });

    if (preset) {
      presetName = preset[0];
    }

    if (presetName) {
      this.nameTarget.textContent = presetName;
      this.inputTarget.value = Object.entries(PERIOD_NAMES).find(([_, v]) => v === presetName)[0];
      this.startDateTarget.value = "";
      this.endDateTarget.value = "";
    } else {
      this.nameTarget.textContent = `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
      this.startDateTarget.value = start.toISOString().split("T")[0];
      this.endDateTarget.value = end.toISOString().split("T")[0];
    }
  }

  _presets() {
    const date = new DateTime();
    const thisMonth = () => {
      const d1 = date.clone();
      d1.setDate(1);

      const d2 = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      return [new DateTime(d1), new DateTime(d2)];
    };

    const lastMonth = () => {
      const d1 = date.clone();
      d1.setMonth(d1.getMonth() - 1);
      d1.setDate(1);

      const d2 = new Date(date.getFullYear(), date.getMonth(), 0);

      return [new DateTime(d1), new DateTime(d2)];
    };

    const allTime = [new DateTime(new Date(2024, 0, 1)), new DateTime(new Date())];

    const ranges = {
      Today: [date.clone(), date.clone()],
      Yesterday: [date.clone().subtract(1, "day"), date.clone().subtract(1, "day")],
      "Last 7 Days": [date.clone().subtract(6, "day"), date.clone()],
      "Last 30 Days": [date.clone().subtract(29, "day"), date.clone()],
      "This Month": thisMonth(),
      "Last Month": lastMonth(),
      "All Time": allTime,
    };

    return ranges;
  }
}
