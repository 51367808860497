import { Controller } from "@hotwired/stimulus";

export default class UIToastController extends Controller {
  static targets = ["item"];

  connect() {
    this.element.addEventListener("turbo:morph-element", (e) => {
      if (e.target === this.element) {
        setTimeout(() => {
          this.automaticallyOpen();
        }, 500);
      }
    });

    this.automaticallyOpen();
  }

  automaticallyOpen() {
    if (this.element.role == "region" && this.element.dataset.auto != "false") {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
      }

      setTimeout(() => {
        this.open();
      }, 100);
      this.scheduleClose();
    }
  }

  open() {
    const toastElement = this.element.querySelector("[data-ui--toast-target='item']");
    this.element.dataset.state = "open";
    this.element.classList.remove("hidden");
    this.showToast(toastElement);
  }

  scheduleClose() {
    this.hideTimeout = setTimeout(() => {
      this.close();
    }, this.element.dataset.duration || 3000);
  }

  close() {
    const toastElement = this.element.querySelector("[data-ui--toast-target='item']");
    this.element.dataset.state = "closed";
    this.element.classList.add("hidden");
    this.closeToast(toastElement);
  }

  showToast(el) {
    if (el) {
      el.dataset.state = "open";
      el.classList.remove("hidden");
    }
  }

  closeToast(el) {
    if (el) {
      el.dataset.state = "closed";
      el.classList.add("hidden");
    }
  }

  trigger() {
    const idTarget = this.element.dataset.target;
    const toastContainer = document.querySelector(`${idTarget}`);
    toastContainer.dataset.state = "open";
    toastContainer.classList.remove("hidden");
    const toastElement = toastContainer.querySelector("[data-ui--toast-target='item']");
    this.showToast(toastElement);
    this.closeToast(toastElement);
  }

  openAll() {
    const toastElements = document.querySelectorAll("[data-ui--toast-target='item']:not([data-visible='false'])");
    toastElements.forEach((toastElement) => {
      this.showToast(toastElement);
    });
  }

  closeAll() {
    const toastElements = document.querySelectorAll("[data-ui--toast-target='item']");
    toastElements.forEach((toastElement) => {
      this.closeToast(toastElement);
    });
  }
}
